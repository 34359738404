import style from '@/Features/NewsComponents/news.module.sass';
import Link from 'next/link';
import { useAppSelector } from '@/Hooks/redux';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import NewsText from '@/Features/NewsComponents/TextRedactor';
import { NewsCardMTButtons } from '@/Features/MT/Buttons';
export const LocalhostingNews = ({ el }: any) => {
	// ==================================
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Check on mount
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleOptionsClick = () => {
		setHovered(prev =>
			prev.isHover && prev.id === el.id
				? { isHover: false, id: NaN }
				: { isHover: true, id: el.id }
		);
	};

	const handleMouseEnter = () => {
		if (!isMobile) {
			setHovered({ isHover: true, id: el.id });
		}
	};

	const handleMouseLeave = () => {
		if (!isMobile) {
			setHovered({ isHover: false, id: el.id });
		}
	};
	// ===============================
	const [isHovered, setHovered] = useState({ isHover: true, id: NaN });

	const { CurrentFontSize, theme } = useAppSelector(state => state.HeaderState);

	return (
		<section
			key={el.id}
			className={style.LocalNewsWrapper}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className={style.WrapperLoacl}>
				<div className={style.logoNameCont}>
					<Link href={`/publisher/${el.publisher_id}`}>
						<Image
							width={16}
							height={16}
							src={el.publisher_logo_url}
							alt='logo'
						/>
					</Link>
					<h3
						className={style.mainTxt}
						style={{
							fontSize: `${16 + CurrentFontSize}px`
						}}
					>
						<NewsText
							StoryId={el.story_id}
							text={el.title}
							url={el.url}
							Id={el.id}
							PublisherId={el.publisher_id}
							el={el}
						/>
					</h3>
				</div>

				<div className={style.ContofOptionsLocal}>
					<div
						className={
							isHovered.isHover === true && isHovered.id === el.id
								? style.LocalOptionsCont
								: style.optionsContClose
						}
					>
						<Link
							className={style.LinkComment}
							href={`/article/${el.id}/story?id=comment-reply${el.story_id}`}
						>
							<div className={style.commentBtnLocal}>
								<svg
									width='17'
									height='15'
									viewBox='0 0 17 15'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M14.075 12.8024C11.6867 15.0948 8.15024 15.59 5.25619 14.3055C4.82895 14.1404 4.47868 14.007 4.14569 14.007C3.21818 14.0123 2.71729 15 0.757744 14.985C-1.41049 14.9684 1.7637 13.125 1.7637 11.7199C1.7637 11.4003 1.63018 11.0701 1.45819 10.6593C0.119153 7.88222 0.63592 4.48702 3.0242 2.1954C6.07296 -0.731676 11.0262 -0.731676 14.075 2.19465C17.1292 5.12625 17.1238 9.87607 14.075 12.8024Z'
										fill='#404040'
									/>
								</svg>
								Обсудить
							</div>
						</Link>
					</div>
					<div className={style.HotBtnContLocal}>
						<NewsCardMTButtons
							boxColor={theme === 'WHITE' ? '#f5f5f5' : '#4d4d4d'}
							StoryId={el.story_id}
							isHovered={isHovered.isHover === true && isHovered.id === el.id}
						/>
					</div>
				</div>
			</div>
			<div
				className={
					isHovered.isHover === true && isHovered.id === el.id
						? style.openOptionsCont
						: style.openOptionsContFalse
				}
				onClick={handleOptionsClick}
			>
				<svg
					width='16'
					height='16'
					viewBox='0 0 16 4'
					fill='#858585'
					xmlns='http://www.w3.org/2000/svg'
				>
					<circle cx='1.75' cy='1.75' r='1.75'></circle>
					<circle cx='7.875' cy='1.75' r='1.75'></circle>
					<circle cx='14' cy='1.75' r='1.75'></circle>
				</svg>
			</div>
		</section>
	);
};
