export const GetLocalNews = ({ pages, loc, whiteList }: any) => {
	return fetch('/api?action=articles', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683,
			count: pages,
			fields: 4194303,
			image_width: 360,
			image_height: 200,
			loc_id: loc,
			filter_publishers: whiteList,
			ctx: 'STORIES'
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
