import style from '@/Features/NewsComponents/news.module.sass';
import Link from 'next/link';
import { useAppSelector } from '@/Hooks/redux';
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
import { useDispatch } from 'react-redux';

import { NewsCardMTButtonsCard } from '@/Features/MT/ButtonsCard';
import { LocalhostingNews } from './components/openComp';
import { getNewsLocal } from '@/Redux/saga/getPostLocalNews';
import { ArticlesMTCounters } from '@/Features/MT/StoryIDCounters';
import { TranslateFunc } from '@/lib/translate';
import { ModalWindowGeo } from '@/Features/ModalWindowGeo/ModalWindowGeo';

export const LocalhostNews = () => {
	// ==================================
	const [isMobile, setIsMobile] = useState(false);
	const WhiteList = useAppSelector(state => state.SourseSlice);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Check on mount
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// ===============================
	const [isContry, setisContry] = useState(false);
	const { SetcurrentLocalId } = HeadingSlice.actions;

	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const { currentLocalId } = useAppSelector(state => state.HeadingSlice);

	const dispatch = useDispatch();
	const { isSwitcherOn, FinalSelect, ClickLocal } = useAppSelector(
		state => state.GeoState
	);

	const Geo = useAppSelector(state => state.getPostLocation);
	const LocalNews: any = useAppSelector(state => state.getPostNewsLocal);

	useEffect(() => {
		dispatch(
			getNewsLocal({
				pages: 3,
				loc: currentLocalId,
				whiteList: WhiteList.WhiteSourses
			})
		);
	}, [
		isSwitcherOn,
		dispatch,
		currentLocalId,
		FinalSelect,
		WhiteList.WhiteSourses
	]);

	const ArrNum = useAppSelector(state => state.getPostProperty);

	useEffect(() => {
		if (isSwitcherOn === true) {
			if (Geo.data.length > 0) {
				Geo.data.map((el: any) => {
					if (el.type === 'CITY') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					} else if (Geo.data.length === 2 && el.type === 'ADM1') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					}
				});
			}
		} else if (isSwitcherOn === false) {
			ArrNum?.data?.locations !== undefined &&
				ArrNum?.data?.locations.map((el: any) => {
					if (el.name === FinalSelect) {
						SetstateSity(el.name);
						dispatch(SetcurrentLocalId(el.id));
						// SetcurrentGeoId(el.id);
						dispatch(setFinalSelect(el.name));
						setisContry(false);
					}
				});
		}
	}, [ArrNum, LocalNews.Loading, isSwitcherOn, FinalSelect]);

	const { SetOpenModalWindow, setFinalSelect } = GeoState.actions;

	const ArrLoading = [1, 2, 3, 4];

	const [stateSit, SetstateSity] = useState('');
	const GeoStateAll: any = useAppSelector(state => state.GeoState);

	useEffect(() => {
		if (!ArrNum.Loading && ArrNum.data.locations) {
			ArrNum.data.locations.map((el: any) => {
				if (el.id === currentLocalId) {
					SetstateSity(el.name);
				}
			});
		}
	});

	return (
		<>
			{FinalSelect.length !== 0 ? (
				<section className={style.microNews}>
					<div className={style.header}>
						{GeoStateAll.IsOpen && <ModalWindowGeo Geo={Geo} />}
						<div
							className={style.location}
							style={{ fontSize: `${15 + CurrentFontSize}px` }}
							onClick={() => dispatch(SetOpenModalWindow()) && console.log('open')}
						>
							<svg
								width='19'
								height='19'
								viewBox='0 0 19 19'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0.942271 7.95077L0.538553 8.11912L0.960627 8.23395L8.65616 10.3276L10.7455 18.0392L10.8599 18.4614L11.0287 18.0579L18.1384 1.05788L18.2793 0.721024L17.9423 0.861555L0.942271 7.95077ZM10.0848 9.03784L10.0626 8.95513L9.98006 8.93223L5.82173 7.7783L15.1151 3.88984L11.2031 13.2002L10.0848 9.03784Z'
									fill='black'
									stroke='#494949'
									strokeWidth='0.3'
								/>
							</svg>
							{FinalSelect}
						</div>
						<Link
							style={{ fontSize: `${14 + CurrentFontSize}px` }}
							className={style.showAll}
							href={`/region/${currentLocalId}/${TranslateFunc(stateSit)}`}
						>
							ПОКАЗАТЬ ВСЕ
						</Link>
					</div>
					<section
						className={
							newsViewBollean == false ? style.listNews : style.listNewsCards
						}
					>
						<>
							{LocalNews.Loading
								? ArrLoading.map(el => {
										return (
											<section className={style.LoadingWrapper} key={el}>
												<div className={style.Wrapper}>
													<div className={style.logoNameCont}>
														<div className={style.LoadingLogo}></div>
														<div className={style.LoadingText}></div>
													</div>
												</div>
											</section>
										);
								  })
								: LocalNews?.data?.map((el: any, ind: any) => {
										return (
											<>
												{newsViewBollean == false ? (
													<LocalhostingNews el={el} />
												) : ind < 2 ? (
													<section key={el.id} className={style.CardWrapper}>
														{el.image == null ? (
															<></>
														) : (
															<Image
																className={style.NewsPreview}
																src={el.image}
																alt='logo'
																width={280}
																height={200}
															/>
														)}

														<div className={style.NewsCardName}>
															<Image
																width={16}
																height={16}
																src={el.publisher_logo_url}
																alt='logo'
															/>
															<h4
																className={style.NewsName}
																style={{
																	fontSize: `${14 + CurrentFontSize}px`
																}}
															>
																{el.publisher_name}
															</h4>
															<ArticlesMTCounters StoryId={el.story_id} />
														</div>
														<div className={style.logoNameCont}>
															<h3
																className={style.mainCardTxt}
																style={{
																	fontSize: `${19 + CurrentFontSize}px`
																}}
															>
																<Link
																	className={style.Links}
																	target='_blank'
																	href={el.url}
																>
																	{el.title}
																</Link>
															</h3>
														</div>
														<NewsCardMTButtonsCard
															StoryId={el.story_id}
															Id={el.id}
														/>
													</section>
												) : (
													<></>
												)}
											</>
										);
								  })}
						</>
					</section>
				</section>
			) : (
				<></>
			)}
		</>
	);
};
