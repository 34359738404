export const GetArticles = ({ topic, whiteList, pages, filter_ids }: any) => {
	return fetch('/api?action=articles', {
		method: 'POST',
		body: JSON.stringify({
			block_id: 84683,
			count: pages,
			fields: 4194303,
			image_width: 360,
			image_height: 200,
			filter_publishers: whiteList,
			ctx: 'STORIES'
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};

export const GetArticlesFilter = ({
	topic,
	whiteList,
	pages,
	filter_ids
}: any) => {
	return fetch('/api?action=articles', {
		mode: 'cors',
		method: 'POST',

		body: JSON.stringify({
			block_id: 84683,
			count: pages,
			fields: 4194303,
			image_width: 360,
			image_height: 200,
			ctx: 'STORIES',
			filter_publishers: whiteList,
			filter_ids: filter_ids
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
