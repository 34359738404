'use client';

import { useAppSelector } from '@/Hooks/redux';
import { LocalhostNews } from '@/Widgets/LocalNews/LocalhostNews';
import News from '@/Widgets/News Card/news';

import style from './page.module.sass';
// import { postSlice, useGetArticlesQuery } from '@/Redux/Api/Requests';

import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import Options from '@/Widgets/Options/options';

// ---------------------
import { getPosts } from '@/Redux/saga/getPostArticle';

interface GetNewsParams {
	block_id: number;
	count: number;
	fields: number;
}
type CallbackFunction = (payload: any) => void;
interface JsAPIDao {
	getNews: (params: GetNewsParams, callback: CallbackFunction) => void;
}

declare global {
	var JsAPI: { Dao: JsAPIDao };
}

export default function MainPage() {
	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	const WhiteList = useAppSelector(state => state.SourseSlice);

	const [PageLin, SetPageLin] = useState(50);
	const [timerActive, setTimerActive] = useState(false);
	const dispatch = useDispatch();

	const Article = useAppSelector(state => state.getPostArticle);

	const [clickRefetch, setclickRefetch] = useState(false);
	const ArticleNews = useAppSelector(state => state.getPostArticleNew);
	const { ref, inView } = useInView({
		threshold: 1
	});

	useEffect(() => {
		if (!timerActive) {
			setTimeout(() => {
				setTimerActive(true);
			}, 100000);
		}
	}, [timerActive, WhiteList.WhiteSourses, dispatch]);

	const [counter, SetCounter] = useState(0);
	const [oldArr, setOldArr] = useState([]);
	const [newArr, setNewArr] = useState<any>([]);
	const [newNews, SetNewNews] = useState<Array<number>>([]);

	useEffect(() => {
		if (Article.data.length > 0 && counter < 1) {
			SetCounter(prevCounter => prevCounter + 1);
			setOldArr(newArr);
			setNewArr(Article.data);
			setclickRefetch(false);
		}
		if (clickRefetch) {
			SetCounter(prevCounter => prevCounter + 1);
			setOldArr(newArr);
			setNewArr(Article.data);
			setclickRefetch(false);

			dispatch(
				getPosts({
					pages: PageLin,
					whiteList: WhiteList.WhiteSourses
				})
			);
		}
	}, [clickRefetch, Article.data, dispatch, PageLin, WhiteList.WhiteSourses]);

	useEffect(() => {
		if (counter > 1) {
			const newIds = newArr.map((el: any) => el.id);
			const oldIds = oldArr.map((el: any) => el.id);
			SetNewNews(newIds.filter((id: any) => !oldIds?.includes(id)));
		}
	}, [newArr, oldArr, counter]);

	useEffect(() => {
		if (inView) {
			SetPageLin(PageLin + 50);
			dispatch(
				getPosts({
					pages: PageLin + 50,
					whiteList: WhiteList.WhiteSourses
				})
			);
		}
	}, [inView]);

	useEffect(() => {
		console.log(WhiteList.WhiteSourses);

		dispatch(
			getPosts({
				pages: PageLin,
				whiteList: WhiteList.WhiteSourses
			})
		);
	}, [dispatch, WhiteList.WhiteSourses]);

	return (
		<>
			{/* <input
				type='button'
				onClick={() => notifyMe()}
				value='Notification'
			></input> */}
			<Options />
			<LocalhostNews />
			<div className={style.childTitleWrapper}>
				<h1
					className={style.childTitle}
					style={{ fontSize: `${15 + CurrentFontSize}px` }}
				>
					ГЛАВНЫЕ НОВОСТИ
				</h1>
			</div>
			<section
				className={
					newsViewBollean == false ? style.NewsContLine : style.NewsCont
				}
			>
				<News
					setClick={setclickRefetch}
					elAll={Article}
					inputRef={ref}
					newNews={timerActive}
					newNewFunc={setTimerActive}
					newNewsArr={ArticleNews}
					countNewNews={newNews}
				/>
			</section>
		</>
	);
}
